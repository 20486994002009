/* search bar */

.jobDetails-searchbar-container {
    width: 100%;
    padding-top: 120px;
}

.jobDetails-container {
    /* border: 1px solid black; */
    width: 85%;
    margin: 0 auto;

}

.jobDetails-container .jobDetails-top {
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
}


/* desc left */
.jobDetails-container .jobDetails-top .jobDetails-desc h3 {
    font-size: 1.15rem;
    color: #245293;
}

.jobDetails-container .jobDetails-top .jobDetails-desc .specialty {
    display: flex;
    align-items: center;
    gap: 10px;
    /* border: 1px solid red; */
    margin: 0.7rem 0;
}

.jobDetails-container .jobDetails-top .jobDetails-desc .specialty .jobDetails-icon {
    color: #245293;
}

.jobDetails-container .jobDetails-top .jobDetails-desc .specialty span {
    font-size: 0.9rem;
    font-size: 18px;
    color: #808080;
}

/* desc left */

/* desc right */
.jobDetails-container .jobDetails-top .jobDetails-apply .findJobs-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    margin: 0.5rem 0;
    margin-right: 3rem;
}

.jobDetails-container .jobDetails-top .jobDetails-apply .findJobs-button button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    background-color: #245293;
    color: #fff;
    font-size: 16px;
    padding: 8px 25px;
    cursor: pointer;
    border-radius: 20px;
    outline: none;
    border: none;
    width: 100%;
}

.jobDetails-container .jobDetails-top .jobDetails-apply .findJobs-button .bookmark {
    background-color: transparent;
    color: #245293;
    border: 1px solid #245293;
    font-weight: 800;

}

/* desc right */
.jobDetails-container .jobDetails-bottom {
    /* border: 1px solid red; */
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.jobDetails-container .jobDetails-bottom .jobDetails-requirements .requirements {
    margin: 2rem 0;
}

.jobDetails-container .jobDetails-bottom .jobDetails-requirements p {
    font-size: 0.85rem;
    color: #808080;
    margin-bottom: 1.5rem;
}

.jobDetails-container .jobDetails-bottom .jobDetails-requirements .requirements h3 {
    font-size: 1.15rem;
    color: #245293;
}

.jobDetails-container .jobDetails-bottom .jobDetails-requirements .requirements ul {
    width: 60%;
    margin: 0 auto;
    font-size: 18px;
    color: #808080;
    margin: 0.5rem 0;
    padding: 0 1rem;
    list-style: disc;
}

.jobDetails-container .jobDetails-bottom .jobDetails-requirements .requirements li {
    margin: 1rem 0;
}


@media only screen and (max-width: 1000px) {
    .jobDetails-searchbar .findJobs-search-box {
        align-items: center;
        flex-direction: column;
        padding: 1.5rem 0.3rem;
        margin-bottom: 25px;
    }

    .jobDetails-searchbar .findJobs-search-box .input-1 {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        padding: 10px 9px;
        margin: 0.2rem 0;
    }

    .jobDetails-searchbar .findJobs-search-box input {
        padding: 2px 5px;
        border: none;
        outline: none;
        border-bottom: 1px solid grey;
        width: 320px;
    }
}

@media only screen and (max-width: 700px) {
    .jobDetails-searchbar {
        display: none;
    }

    .jobDetails-container .jobDetails-top {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-top: 1.5rem;
        gap: 1rem;
    }

    .jobDetails-container .jobDetails-bottom .jobDetails-requirements .requirements ul {
        width: 99%;
        margin: 0 auto;
        font-size: 18px;
        color: #808080;
        margin: 0.5rem 0;
        padding: 0 1rem;
        list-style: disc;
    }
}