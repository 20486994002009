.eachchat-bg {
  display: flex;
  gap: 0.5rem;
}

.eachchat-bg .img-cover {
  display: flex;
  justify-content: flex-end;
  align-items: end;
}

.eachchat-bg .chat-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  max-width: 320px;
  padding: 1rem;
}

.left {
  color: #fff;
  background-color: #20a4d3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.right {
  color: #465174;
  background-color: #F3F5F7;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
