.contain-joblisting {
  width: 100%;
  padding-top: 120px;
}

.joblistings-searchbar {
  width: 85%;
  min-height: 300px;
  background:url(../../assets/bg-g.png) ;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.filter-text-head {
  margin: 2rem;
  display: none;
  cursor: pointer;
}

.joblistings-searchbar .findJobs-search-box {
  border-radius: 25px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0.1rem 1rem 0.1rem 1.5rem;
  margin-bottom: 25px;
  width: 85%;
  margin: 0 auto;
}

.joblistings-searchbar .findJobs-search-box .input-1 {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 12px 12px;
  border-right: 1.5px solid #a9dbee;
  width: 35%;
}

.joblistings-searchbar .findJobs-search-box input {
  padding: 2px 5px;
  border: none;
  outline: none;
  width: 320px;
}

.joblistings-searchbar .findJobs-search-box .findJobs-button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 30%;
}

.joblistings-searchbar .findJobs-search-box .findJobs-button button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  background-color: #245293;
  color: #fff;
  font-size: 16px;
  padding: 8px 25px;
  cursor: pointer;
  border-radius: 20px;
  outline: none;
  border: none;
  width: 100%;
}

.jobListings-searchbox {
  padding: 0.2rem 0;
  width: 85%;
  margin: 1.5rem auto;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.jobListings-searchbox .filter-box-big-screen {
  width: 30%;
  height: 120vh;
}

.jobListings-searchbox .jobListings-search-filter-results {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;
}

.jobListings-searchbox .jobListings-search-filter-results .joblistings-jobitem {
  background-color: #fff;
  cursor: pointer;
  border-radius: 10px;
}

.filter-small-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* border: 1px solid red; */
}

@media only screen and (max-width: 900px) {
  .joblistings-searchbar {
    width: 95%;
  }

  .joblistings-searchbar .findJobs-search-box {
    padding: 0.1rem 0.5rem 0.1rem 1rem;

    width: 95%;
  }

  .joblistings-searchbar .findJobs-search-box .input-1 {
    margin: 8px 8px;
  }

  .joblistings-searchbar .findJobs-search-box input {
    padding: 2px 2px;
  }

  .jobListings-searchbox {
    padding: 0.2rem 0;
    width: 95%;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 750px) {
  .joblistings-searchbar {
    display: none;
  }

  .jobListings-searchbox {
    margin-top: 7rem;
  }

  .jobListings-searchbox .filter-box-big-screen {
    display: none;
  }

  .jobListings-searchbox .jobListings-search-filter-results {
    width: 95%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 550px) {
  .joblistings-searchbar {
    width: 95%;
    /* border: 1px solid red; */
    background: none;
  }

  .jobListings-searchbox .jobListings-search-filter-results {
    width: 100%;
    margin-top: 1rem;
  }
}
