.messages-container {
  margin: 1rem 1.2rem;
}

.messages-container h2 {
  color: #245293;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px; /* 38.4px */
  letter-spacing: -1px;
}

.message-app-content {
  margin: 2rem 0;
  background-color: #fff;
  border-radius: 24px;
  padding: 0.8rem 0;
  display: flex;
}

.message-app-content .conversation-list {
  width: 35%;
  border-right: 1px solid rgba(128, 128, 128, 0.15);
}

.message-app-content .conversation-list .round-up {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  height: 700px;
  overflow: scroll;
  overflow-x: hidden;
}

.message-app-content .conversation-list .round-up .divider {
  background-color: rgba(128, 128, 128, 0.15);
  height: 1px;
  width: 100%;
}

.message-app-content .conversation-list .conversation-list-text {
  padding: 0.8rem 2.5rem 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.15);
}

.message-app-content .conversation-list .conversation-list-text h3 {
  color: #5372ea;
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  padding: 0 0.5rem 1.2rem;
  border-bottom: 5px solid #5372ea;
  width: fit-content;
}

.message-app-content .each-conversation {
  width: 65%;
}

.message-app-content .each-conversation .each-conversation-header {
  border-bottom: 1px solid rgba(128, 128, 128, 0.15);
  padding: 0.3rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-app-content .each-conversation .each-conversation-header .profile {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .profile
  .profile-text {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .profile
  .profile-text
  h6 {
  color: #465174;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .profile
  .profile-text
  .profile-status {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .profile
  .profile-text
  .profile-status
  .active-status {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .profile
  .profile-text
  .profile-status
  p {
  color: #626b89;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.message-app-content .each-conversation .each-conversation-header .toggle-box {
  display: flex;
  gap: 5px;
  align-items: center;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-box
  span {
  color: #626b89;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  word-wrap: break-word;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch {
  position: relative;
  display: inline-block;
  width: 27px;
  height: 20px;
  cursor: pointer;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch
  input[type="checkbox"] {
  display: none;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch
  .toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: background-color 0.3s ease-in-out;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch
  .toggle-switch-background
  .toggle-switch-handle {
  position: absolute;
  top: 4px;
  left: 3px;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -35px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  transition: color 0.3s ease-in-out;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch
  input[type="checkbox"]:checked
  + .toggle-switch-handle {
  transform: translateX(45px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #245293;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch
  input[type="checkbox"]:checked
  + .toggle-switch-background {
  background-color: #245293;
  box-shadow: inset 0 0 0 2px #245293;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch
  input[type="checkbox"]:checked
  + .toggle-switch:before {
  content: "On";
  color: #245293;
  right: -15px;
}

.message-app-content
  .each-conversation
  .each-conversation-header
  .toggle-switch
  input[type="checkbox"]:checked
  + .toggle-switch-background
  .toggle-switch-handle {
  transform: translateX(8px);
}

.message-app-content .each-conversation .each-conversation-content {
  padding: 1rem 1rem;
}

.message-app-content
  .each-conversation
  .each-conversation-content
  .main-chat-broad {
  min-height: 600px;
  margin: 0 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-end;
}

.message-app-content
  .each-conversation
  .each-conversation-content
  .message-text-bg {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.message-app-content
  .each-conversation
  .each-conversation-content
  .message-text-bg
  .alternate-text-bg {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.message-app-content
  .each-conversation
  .each-conversation-content
  .message-text-bg
  .alternate-text-bg
  button {
  border-radius: 12px;
  border: 1px solid #808080;
  height: 35px;
  padding: 0 0.5rem;
  background-color: #fff;
  cursor: pointer;
  color: #808080;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.message-app-content .each-conversation .message-text-bg .text-field-bg {
  border-radius: 12px;
  border: 0.5px solid #465174;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding-left: 1rem;
}

.message-app-content
  .each-conversation
  .message-text-bg
  .text-field-bg
  .text-field {
  border: none;
  outline: none;
  width: 80%;
  height: 100%;
}

.message-app-content
  .each-conversation
  .message-text-bg
  .text-field-bg
  .btns-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.message-app-content
  .each-conversation
  .message-text-bg
  .text-field-bg
  .btns-input
  .send-btn-input {
  border-radius: 12px;
  background-color: #f4fcff;
  width: 58px;
  height: 54px;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .messages-container {
    margin: 6rem 1.2rem 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .message-app-content {
    margin: 1.5rem 0;
    border-radius: 18px;
    padding: 0.8rem 0;
    display: block;
  }

  .message-app-content .conversation-list {
    width: 100%;
  }

  .message-app-content .conversation-list .conversation-list-text {
    padding: 0.8rem 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-app-content .conversation-list .conversation-list-text h3 {
    padding: 0 0.5rem 0.5rem;
  }

  .message-app-content .conversation-list .round-up {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    height: auto;
    overflow: visible;
    overflow-x: visible;
  }

  .message-app-content .each-conversation {
    width: 100%;
  }

  .hide-cover {
    display: none;
  }

  .show-cover {
    display: block;
  }

  .message-app-content
    .each-conversation
    .each-conversation-content
    .message-text-bg
    .alternate-text-bg {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 650px) {
  .messages-container {
    margin: 6rem 0.5rem 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .message-app-content .each-conversation .each-conversation-header {
    border-bottom: 1px solid rgba(128, 128, 128, 0.15);
    padding: 0.3rem 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .message-app-content .each-conversation .each-conversation-header .profile {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    width: 65%;
  }

  .message-app-content
    .each-conversation
    .each-conversation-header
    .toggle-box {
    display: flex;
    gap: 0;
    align-items: center;
  }

  .message-app-content
    .each-conversation
    .each-conversation-header
    .toggle-box
    span {
    font-size: 12px;
    line-height: 18px;
  }
}
