.license-container {
  display: flex;
  flex-direction: row;
}

/* left side */
.license-container .license-left {
  position: fixed;
  width: 100%;
  height: 100%;
  width: 40%;
  max-height: 100vh;
  overflow-y: hidden;
}

.license-container .license-left .license-left-content {
  background: url(../../../assets/bg-g.png);
  padding: 0.4rem 1.5rem 2rem 5rem;
  height: 100vh;
}

.license-container .license-left .logo {
  height: 60px;
  width: 60px;
  margin: 0.3rem 0 2rem 0;
  cursor: pointer;
}

.license-container .license-left .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.license-container .license-left .license-left-text h3 {
  font-size: 36px;
  color: #fff;
  width: 70%;
  margin: 0 auto;
  margin: 1rem 0;
  font-weight: 800;
  line-height: 40px;
}

.license-container .license-left .license-left-text p {
  font-size: 16px;
  color: #fff;
  width: 70%;
  margin: 0 auto;
  margin: 0.4rem 0;
  margin-bottom: 0rem;
}

.license-container .license-left .license-left-image {
  margin: 0 auto;
  position: relative;
}

.license-container .license-left .license-left-image .doc {
  width: fit;
  height: fit;
  object-fit: cover;
}

.license-container .license-left .license-left-image .white-box {
  position: absolute;
  right: 30px;
  top: 6rem;
}

/* left side */

/* right side */

.license-container .license-right {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 5rem;
  height: fit-content;
  min-height: 100vh;
}

.license-container .license-right .license-right-body {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.license-container .license-right .license-right-body .skip {
  width: 100%;
  text-align: right;
  color: #245293;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-decoration-line: underline;
}

.license-container .license-right .license-right-body .skip p {
  cursor: pointer;
}

.license-container .license-right .license-right-body .license-right-content {
  margin: 6rem 0 0.5rem;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license {
  margin: 2rem 0;
  border: 1px dashed #808080;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license
  .license-img-cover {
  width: 100%;
  height: 300px;
  background-color: red;
  position: relative;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license
  .license-img-cover
  .upload-btn-bg {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  text-align: center;
  width: 100%;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license
  .license-img-cover
  button {
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  text-align: center;
  color: #245293;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  border-radius: 100px;
  border: 1.5px solid #245293;
  background:#fff;
  width: 143px;
  height: 40px;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license
  .license-img-cover
  img {
  width: 100%;
  height: 100%;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license
  .inner-bg-file-license {
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license
  h4 {
  margin-top: 0.8rem;
  color: #021424;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license
  p {
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.5px;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-file-license
  button {
  margin-top: 1rem;
  width: 143px;
  height: 40px;
  border-radius: 100px;
  background: #245293;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  border: none;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  h2 {
  color: #021424;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: -0.54px;
  align-self: stretch;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .head-desc {
  color: #808080;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 70%;
  margin-top: 1rem;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .license-right-checkbox {
  display: flex;
  align-self: flex-start;
  gap: 0.8rem;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .license-right-checkbox
  p {
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.5px;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .license-right-checkbox
  p
  span {
  color: #245293;
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.5px;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-license {
  width: 100%;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .bg-license
  .license-btn {
  width: 100%;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #245293;
  color: #fff;
  cursor: pointer;
  margin-top: 1rem;
  height: 45px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .skip-bg{
  margin-top: 1rem;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .skip-btn{
  color: #245293;
  font-weight: 700;
  font-size: 1.2rem;
  cursor: pointer;
}

.license-container
  .license-right
  .license-right-body
  .license-right-content
  .skip-text {
  font-weight: 600;
  font-size: 1.2rem;
}

@media only screen and (max-width: 1500px) {
  .license-container .license-left .license-left-content {
    padding: 0.4rem 1rem 2rem 2.5rem;
  }

  .license-container .license-left .license-left-text h3 {
    font-size: 30px;
    color: #fff;
    width: 80%;
    margin: 0 auto;
    margin: 0.5rem 0;
  }

  .license-container .license-left .license-left-image .doc {
    height: 450px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 1200px) {
  .license-container .license-right {
    position: static;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0 5rem;
    background-color: #fff;
  }

  .license-container .license-right .license-right-body {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .license-container {
    flex-direction: column;
  }

  .license-container .license-left {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .license-container .license-right .license-right-body {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .license-container .license-right .license-right-body .license-right-content {
    margin: 2rem 0 0.5rem;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .license-container
    .license-right
    .license-right-body
    .license-right-content
    .head-desc {
    width: 85%;
  }
}

@media only screen and (max-width: 550px) {
  .license-container
    .license-right
    .license-right-body
    .license-right-content
    h2 {
    font-size: 24px;
    line-height: 28px;
  }
}
