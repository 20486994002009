.profile-edit-container {
  flex-basis: 100%;
  margin: 1rem;
}

.profile-edit-container .heading {
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.profile-edit-container h6 {
  color: #465174;
  font-size: 28px;
  font-weight: 700;
  line-height: 56px;
}

.profile-edit-container .profile-edit-content {
  background-color: #fff;
  border-radius: 15px;
  padding: 0 3rem 3rem;
}

.profile-edit-container .profile-edit-content .part {
  padding: 3rem 0;
}

.profile-edit-container .profile-edit-content hr {
  border: 1px solid #efefef;
}

.profile-edit-container .profile-edit-content h2 {
  color: #245293;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
}

.profile-edit-container .profile-edit-content p {
  padding-top: 1.1rem;
  color: #777;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.profile-edit-container .profile-edit-content .part .input-group {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-edit-container
  .profile-edit-content
  .part
  .input-group
  .upload-img-bg {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-edit-container
  .profile-edit-content
  .part
  .input-group
  .upload-img-bg
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid gray;
}

.profile-edit-container
  .profile-edit-content
  .part
  .input-group
  .inner-career-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-edit-container .profile-edit-content .part .input-group .add-skill {
  margin-top: 0.1rem;
  width: fit-content;
}

.profile-edit-container .profile-edit-content .part .input-group .skills-lists {
  width: 650px;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.profile-edit-container
  .profile-edit-content
  .part
  .input-group
  .skills-lists
  .each-skill {
  border-radius: 10px;
  background-color: #efefef;
  padding: 5px 10px;
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}

.profile-edit-container
  .profile-edit-content
  .part
  .input-group
  .skills-lists
  .each-skill
  span {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  text-transform: lowercase;
  margin-left: 1rem;
}

.profile-edit-container
  .profile-edit-content
  .part
  .input-group
  .auto-complete-con {
  width: 50%;
}

.profile-edit-container .profile-edit-content .items {
  padding-top: 1.5rem;
  padding-left: 20px;
}

.profile-edit-container .profile-edit-content .items ul li .item {
  color: #777;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.profile-edit-container .profile-edit-content .privacy {
  margin-top: 1.5rem;
}

.profile-edit-container .profile-edit-content .privacy span {
  text-decoration: underline;
}

.profile-edit-container .profile-edit-content button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220.42px;
  height: 40px;
  border-radius: 100px;
  background: #3a649e;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  border: none;
  padding: 0 15px;
  cursor: pointer;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch-handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -35px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  transition: color 0.3s ease-in-out;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-handle {
  transform: translateX(45px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #3a649e;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
  background-color: #3a649e;
  box-shadow: inset 0 0 0 2px #3a649e;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch:before {
  content: "On";
  color: #3a649e;
  right: -15px;
}

.toggle-switch
  input[type="checkbox"]:checked
  + .toggle-switch-background
  .toggle-switch-handle {
  transform: translateX(18px);
}

@media only screen and (max-width: 1000px) {
  .profile-edit-container {
    flex-basis: 100%;
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 750px) {
  .profile-edit-container
    .profile-edit-content
    .part
    .input-group
    .skills-lists {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .profile-edit-container {
    margin: 5rem 0.4rem;
  }

  .profile-edit-container .heading {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  .profile-edit-container .profile-edit-content {
    padding: 0 1rem 2rem;
  }

  .profile-edit-container .profile-edit-content .part {
    padding: 2rem 0;
  }
}

@media only screen and (max-width: 600px) {
  .profile-edit-container
    .profile-edit-content
    .part
    .input-group
    .auto-complete-con {
    width: 100%;
  }
}
