.dashboard-container .dashboard-right {
  width: 100%;
  padding: 1.2rem 1.2rem 0;
}

.dashboard-container .dashboard-right h1 {
  font-size: 32px;
  color: #465174;
  font-weight: 700;
  line-height: 56px;
}

.dashboard-container .dashboard-right .dashboard-right-bottom {
  margin-top: 1rem;
  padding: 0 1rem;
}

/* info box */
/* info box */
.dashboard-container .dashboard-right .dashboard-right-bottom .info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
}

.dashboard-container .dashboard-right .dashboard-right-bottom .info-box h4 {
  color: #465174;
  font-size: 30px;
}

/* explore more */
/* explore more */
.dashboard-container .dashboard-right-bottom .exploreMore-container {
  background-color: #fff;
  /* height: 450px; */
  border-radius: 24px;
  padding: 1.5rem;
  margin-bottom: 1rem;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-top {
  height: 165px;
  background: url(../../assets/bg-g.png);
  border-radius: 20px;
  padding: 1.5rem;
  margin-bottom: 0.7rem;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-top
  h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 1.2rem;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-top
  .home-box-one-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  margin: 0.8rem 0;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-top
  .home-box-one-button
  button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background-color: #245293;
  color: #fff;
  font-size: 16px;
  padding: 8px 20px;
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  outline: none;
  border: none;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom {
  padding: 10px 10px;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  h4 {
  font-size: 16px;
  margin-bottom: 1rem;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-jobs {
  display: flex;
  gap: 10px;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-Jobs-box {
  width: 30%;
  padding: 1.2rem 0.7rem;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-Jobs-box
  .exploreMore-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-Jobs-box
  .exploreMore-title-box
  h4 {
  font-size: 16px;
  color: #3a649e;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-Jobs-box
  .exploreMore-title-box
  span {
  background-color: #36a695;
  color: #fff;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 5px;
  line-height: 11px;
  font-weight: 600;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-Jobs-box
  .exploreMore-title-box
  .image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-Jobs-box
  .exploreMore-title-box
  .image
  img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-Jobs-box
  .desc
  p {
  color: Grey;
  font-size: 11px;
  line-height: 19px;
  margin: 1.2rem 0;
}

.dashboard-container
  .dashboard-right-bottom
  .exploreMore-container
  .exploreMore-bottom
  .exploreMore-Jobs-box
  .applicants {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #20a4d3;
  cursor: pointer;
}

/* explore more */
/* explore more */

/* applications info */
/* applications info */
.dashboard-container .dashboard-right-bottom .applications-info-container {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 24px;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .mobile-application-jobs {
  display: none;
}

.dashboard-container .dashboard-right-bottom .applications-info-container h4 {
  margin-bottom: 0.6rem;
  font-size: 20px;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-heading {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #a9dbee1a;
  padding: 0.8rem 0 0.8rem 1rem;
  border-radius: 7px;
  margin-top: 1.5rem;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-heading
  .head-1 {
  width: 55%;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-heading
  .head {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-content
  .applications-info-content-box {
  margin: 5px 0;
  padding: 0.8rem 0 0.8rem 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-content
  .applications-info-content-box
  .content-1 {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 55%;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-content
  .applications-info-content-box
  .status {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: rgba(251, 141, 13, 0.15);
  color: #fb8d0d;
  padding: 6px 10px 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: fit-content;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-content
  .applications-info-content-box
  .date {
  font-size: 14px;
  line-height: 20px;
  color: #1c2533;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-content
  .applications-info-content-box
  .content-1
  .img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-content
  .applications-info-content-box
  .content-1
  .img
  img {
  width: 90%;
  margin: 0 5%;
  height: 100%;
  border-radius: 50%;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-content
  .applications-info-content-box
  .content-1
  .title
  h6 {
  font-size: 16px;
}

.dashboard-container
  .dashboard-right-bottom
  .applications-info-container
  .applications-info-content
  .applications-info-content-box
  .content-1
  .title
  .icon-loc-box {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #959595;
}
/* applications info */
/* applications info */

@media only screen and (max-width: 1200px) {
  .dashboard-container .dashboard-right h1 {
    font-size: 28px;
    line-height: 48px;
  }
  .dashboard-container
    .dashboard-right-bottom
    .exploreMore-container
    .exploreMore-bottom
    .exploreMore-Jobs-box
    .exploreMore-title-box
    h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .applications-info-heading
    .head {
    font-size: 12px;
    line-height: 15px;
  }
  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .applications-info-content
    .applications-info-content-box
    .content-1
    .title
    h6 {
    font-size: 14px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .applications-info-content
    .applications-info-content-box
    .content-1
    .title
    .icon-loc-box {
    font-size: 10px;
  }
  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .applications-info-content
    .applications-info-content-box
    .status {
    padding: 3px 5px 2px;
    font-size: 8px;
    line-height: 12px;
    font-weight: 500;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .applications-info-content
    .applications-info-content-box
    .date {
    font-size: 10px;
    line-height: 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .dashboard-container .dashboard-right {
    padding: 5rem 0.5rem 0;
  }
}

@media only screen and (max-width: 750px) {
  .dashboard-container .dashboard-right {
    padding: 5rem 0.3rem 0;
  }

  .dashboard-container .dashboard-right .dashboard-right-bottom {
    margin-top: 1.5rem;
    padding: 0 0.5rem;
  }

  .dashboard-container .dashboard-right .dashboard-right-bottom .info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 1rem;
  }

  .dashboard-container .dashboard-right-bottom .exploreMore-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 0.8rem;
    margin-bottom: 1rem;
  }

  .dashboard-container
    .dashboard-right-bottom
    .exploreMore-container
    .exploreMore-top {
    height: 140px;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 0.7rem;
  }

  .dashboard-container
    .dashboard-right-bottom
    .exploreMore-container
    .exploreMore-bottom {
    padding: 5px 4px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .exploreMore-container
    .exploreMore-bottom
    .exploreMore-jobs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .exploreMore-container
    .exploreMore-bottom
    .exploreMore-Jobs-box {
    width: 100%;
    padding: 0.5rem 0.2rem;
  }

  .dashboard-container .dashboard-right-bottom .applications-info-container {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 8px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .desktop-view {
    display: none;
  }

  .dashboard-container .dashboard-right-bottom .applications-info-container h4 {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs {
    display: block;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs {
    border-top: 1px solid #efefef;
    display: flex;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding-top: 15px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex
    .label {
    color: #777;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex
    .content-1 {
    display: flex;
    gap: 10px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex
    .content-1
    .img {
    border-radius: 50%;
    object-fit: contain;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex
    .content-1
    .img
    img {
    margin: 0 5%;
    border-radius: 50%;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex
    .content-1
    .title
    h6 {
    font-size: 15px;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex
    .content-1
    .title
    .icon-loc-box {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #959595;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex
    .status {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: rgba(251, 141, 13, 0.15);
    color: #fb8d0d;
    padding: 6px 10px 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    width: fit-content;
  }

  .dashboard-container
    .dashboard-right-bottom
    .applications-info-container
    .mobile-application-jobs
    .all-jobs-app
    .job-single
    .flex
    .date {
    font-size: 14px;
    line-height: 18px;
  }
}
