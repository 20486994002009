.admin-dashboard-container .admin-dashboard-right {
  width: 100%;
  padding: 1rem 2rem;
}

.admin-dashboard-container .admin-dashboard-right .heading-right-top {
  color: #465174;
  font-size: 24px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0px;
  text-align: left;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom {
  background-color: #fff;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 10px;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-info-box {
  display: flex;
  gap: 10px;
  margin-bottom: 1.2rem;
  width: 100%;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-info-box
  .admin-info-right {
  width: 25%;
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  padding: 0.6rem;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-info-box
  .admin-info-right
  .hidden-figure {
  visibility: hidden;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-info-box
  .admin-info-right
  span {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-info-box
  .admin-info-right
  h4 {
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: -2px;
  text-align: left;
}

.admin-dashboard-container .admin-dashboard-right-bottom .admin-info-box .one {
  background-color: #f5a656;
}

.admin-dashboard-container .admin-dashboard-right-bottom .admin-info-box .two {
  background-color: #20a4d3;
}

.admin-dashboard-container
  .admin-dashboard-right-bottom
  .admin-info-box
  .three {
  background-color: #62d8e0;
}

.admin-dashboard-container .admin-dashboard-right-bottom .admin-info-box .four {
  background-color: #36a695;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container {
  border-radius: 8px;
  display: flex;
  padding: 0.5rem 0.3rem;
  gap: 2%;
  margin-bottom: 1.5rem;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .graph-box {
  flex-basis: 68%;
  border-radius: 10px;
  border: 1px solid rgb(236, 236, 236);
  padding: 1rem;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .graph-box
  .graph-items {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.3rem;
  margin-bottom: 1rem;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .graph-box
  .graph-items
  .single-graph-item {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .graph-box
  .graph-items
  .single-graph-item
  .circle-graph {
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .graph-box
  .graph-items
  .single-graph-item
  p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  h6 {
  color: #245293;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .summary-box {
  flex-basis: 30%;
  border-radius: 10px;
  padding: 16px 10px;
  border: 1px solid rgb(236, 236, 236);
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .summary-box
  .contain-summary {
  height: 370px;
  overflow-y: scroll;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .summary-box
  .cards-total {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .summary-box
  .revenue-total {
  background-color: #245293;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.8rem;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .summary-box
  .revenue-total
  .revenue-context {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .summary-box
  .revenue-total
  .revenue-context
  h4 {
  font-weight: 800;
  font-size: 18px;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .graph-container
  .summary-box
  .revenue-total
  .revenue-context
  p {
  font-size: 14px;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs {
  padding: 1rem;
  border-radius: 7px;
  border: 1px solid #80808026;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  h4 {
  font-size: 17px;
  margin-bottom: 1rem;
  color: #465174;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box
  .single-job-box {
  width: 30%;
  padding: 1.2rem 0.7rem;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box
  .single-job-box
  .single-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box
  .single-job-box
  .single-title-box
  h4 {
  font-size: 16px;
  color: #3a649e;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box
  .single-job-box
  .single-title-box
  span {
  background-color: #36a695;
  color: #fff;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 5px;
  line-height: 11px;
  font-weight: 600;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box
  .single-job-box
  .single-title-box
  .image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box
  .single-job-box
  .single-title-box
  .image
  img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box
  .single-job-box
  .desc
  p {
  color: Grey;
  font-size: 11px;
  line-height: 19px;
  margin: 1.2rem 0;
}

.admin-dashboard-container
  .admin-dashboard-right
  .admin-dashboard-right-bottom
  .admin-openJobs
  .admin-openJobs-box
  .single-job-box
  .applicants {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #20a4d3;
  cursor: pointer;
  font-size: 12.057px;
  font-weight: 600;
  line-height: 24.481px;
}

@media only screen and (max-width: 1100px) {
  .admin-dashboard-container
    .admin-dashboard-right
    .admin-dashboard-right-bottom
    .graph-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .admin-dashboard-container
    .admin-dashboard-right
    .admin-dashboard-right-bottom
    .admin-info-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 1.2rem;
    width: 100%;
    justify-content: space-between;
  }
  .admin-dashboard-container
    .admin-dashboard-right
    .admin-dashboard-right-bottom
    .admin-info-box
    .admin-info-right {
    width: 48%;
    height: 100px;
  }
}

@media only screen and (max-width: 750px) {
  .admin-dashboard-container .admin-dashboard-left {
    display: none;
  }

  .admin-dashboard-container .admin-dashboard-right {
    width: 100%;
    padding: 2rem 1rem;
  }

  .admin-dashboard-container .admin-dashboard-right .heading-right-top {
    font-size: 29px;
    color: #465174;
    margin-top: 2.4rem;
  }

  .admin-dashboard-container
    .admin-dashboard-right
    .admin-dashboard-right-bottom
    .admin-openJobs
    .admin-openJobs-box
    .single-job-box {
    width: 45%;
    padding: 1.2rem 0.7rem;
  }
}

@media only screen and (max-width: 600px) {
  .admin-dashboard-container
    .admin-dashboard-right
    .admin-dashboard-right-bottom
    .admin-info-box
    .admin-info-right {
    width: 100%;
  }
}

@media only screen and (max-width: 530px) {
  .admin-dashboard-container .admin-dashboard-right {
    width: 100%;
    padding: 2rem 0.7rem;
  }

  .admin-dashboard-container
    .admin-dashboard-right
    .admin-dashboard-right-bottom
    .admin-openJobs {
    padding: 0.5rem;
    border-radius: 7px;
  }

  .admin-dashboard-container
    .admin-dashboard-right
    .admin-dashboard-right-bottom
    .admin-openJobs
    .admin-openJobs-box
    .single-job-box {
    width: 100%;
    padding: 1.2rem 0.7rem;
  }

  .admin-dashboard-container
    .admin-dashboard-right
    .admin-dashboard-right-bottom
    .graph-container
    .graph-box {
    flex-basis: 68%;
    border-radius: 10px;
    border: 1px solid rgb(236, 236, 236);
    padding: 0.5rem;
  }
}
