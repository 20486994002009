.timeframe-bg label {
  color: #021424;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.timeframe-bg .timeframe-input-bg {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.timeframe-bg {
  width: 40%;
}

@media only screen and (max-width: 900px) {
  .timeframe-bg {
    width: 100%;
  }
}
