.app-container {
    overflow-x: hidden;
}
.home-container {
  padding-bottom: 3rem;
  width: 100%;
  /* padding: 110px 0 0; */
  /* margin-top: 2rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* responsive background */
/* responsive background */
.home-container .home-box-responsive {
  width: 85%;
  background: url(../../assets/bg-g.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin: 110px auto 0;
  border-radius: 19px;
  padding: 3rem 1rem;
  margin-bottom: 2rem;
  position: relative;
}

.home-container .home-box-responsive .scope-img-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 150px;
  left: 70px;
}

.home-container .home-box-responsive .scope-img-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  right: 70px;
}

/* responsive background */
/* responsive background */

.home-box-one .home-box-one-content {
  padding: 1rem;
  width: 48%;
  margin: 0 auto;
  margin-top: 4rem;
}

.home-box-one .home-box-one-content h2 {
  font-size: 47px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  line-height: 1;
}

.home-box-one .home-box-one-content p {
  font-size: 18px;
  color: #fff;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: center;
}

.home-box-one .home-box-one-search-box {
  border-radius: 30px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0.1rem 0.3rem;
  margin-bottom: 25px;
}

.home-box-one .home-box-one-search-box .input-1 {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 9px;
  width: 35%;
}

.home-box-one .home-box-one-button {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  padding-right: 10px;
}

.home-box-one .home-box-one-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
  background-color: #245293;
  color: #fff;
  font-size: 12px;
  padding: 6px 15px;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}

.home-box-one .home-box-one-search-box input {
  padding: 2px 5px;
  border: none;
  outline: none;
  border-right: 1px solid grey;
}

.home-box-one .community-box {
  padding: 10px;
}

.home-box-one .community-box-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.home-box-one .community-box p {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (max-width: 1400px) {
  .home-box-one .home-box-one-content {
    padding: 1rem;
    width: 60%;
    margin: 0 auto;
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 1250px) {
  .home-container .home-box-responsive {
    width: 90%;
  }
  .home-box-one .home-box-one-search-box .input-1 {
    width: 40%;
  }

  .home-box-one .home-box-one-button {
    width: 20%;
    padding-right: 0;
  }

  .home-box-one .home-box-one-content {
    width: 70%;
  }

  .home-container .home-box-responsive .scope-img-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 80px;
    left: 50px;
  }
}

@media only screen and (max-width: 900px) {
  .home-container .home-box-responsive .scope-img-1 {
    display: none;
  }

  .home-container .home-box-responsive .scope-img-2 {
    display: none;
  }

  .home-container .home-box-responsive {
    background: url(../../assets/bg-g.png);
    width: 95%;
    padding: 2rem 1rem;
  }

  .home-box-one .home-box-one-content {
    padding: 1rem 0.5rem;
    width: 100%;
  }

  .home-box-one .home-box-one-button {
    width: 120px;
  }

  .home-box-one .home-box-one-button button {
    gap: 5px;
    font-size: 14px;
    padding: 8px 6px;
  }

  .home-box-one .home-box-one-search-box {
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0.6rem;
    gap: 15px;
  }

  .home-box-one .home-box-one-search-box .input-1 {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    width: 100%;
    border-bottom: 1px solid #c9c8c8;
  }

  .home-box-one .home-box-one-button {
    width: 100%;
    margin-top: 0.5rem;
  }

  .home-box-one .home-box-one-button button {
    gap: 18px;
    width: 100%;
    padding: 0.4rem 0;
    font-size: 20px;
  }

  .home-box-one .home-box-one-search-box input {
    padding: 10px 5px;
    width: 90%;
    border: none;
  }

  .home-box-one .community-box p {
    width: 65%;
    margin: 0 auto;
    text-align: center;
  }

  .home-box-one .home-box-one-content h2 {
    margin-bottom: 15px;
    font-size: 35px;
  }

  .home-box-one .home-box-one-content p {
    font-size: 19px;
    margin-bottom: 30px;
    text-align: center;
  }

  .home-box-one .community-box {
    padding: 10px;
    margin-top: 2rem;
  }

  .home-box-one .community-box p {
    width: 90%;
  }
}
