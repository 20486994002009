.paymentsection-container {
  display: flex;
  padding: 1.5rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 4px 6px 12px 0px rgba(36, 82, 147, 0.04);
}

.paymentsection-container .section-one {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.paymentsection-container .section-one .image-bg {
  padding: 1rem;
  border-radius: 50%;
  background-color: #f4fcff;
}

.paymentsection-container .section-one .text-bg {
  display: flex;
  gap: 0.7rem;
  flex-direction: column;
}

.paymentsection-container .section-one .text-bg h5 {
  color: #245293;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.paymentsection-container .section-one .text-bg p {
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.5px;
}

.paymentsection-container .section-one .text-bg .acc-no {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #245293;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.paymentsection-container .section-one .text-bg .acc-no h6 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.paymentsection-container .section-two {
  display: flex;
  align-items: center;
  gap: 10px;
}

.paymentsection-container .section-two button {
  border-radius: 100px;
  background: #245293;
  border: 1px solid #245293;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 12px 24px;
  cursor: pointer;
}

.paymentsection-container .section-two .withdraw {
  background: #fff;
  border: 1px solid #245293;
  color: #245293;
}

@media only screen and (max-width: 800px) {
  .paymentsection-container {
    display: flex;
    padding: 1.5rem 1.5rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
    gap: 1rem;
  }

  .paymentsection-container .section-two {
    margin-left: 5.5rem;
  }
}

@media only screen and (max-width: 650px) {
  .paymentsection-container {
    padding: 1.5rem 0.8rem;
  }
}

@media only screen and (max-width: 400px) {
  .paymentsection-container .section-two {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .paymentsection-container .section-two button {
    padding: 8px 18px;
  }
}
