.job-box {
  border-radius: 13.057px;
  border: 0.816px solid rgba(151, 173, 204, 0.15);
  padding: 1rem;
  position: relative;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.job-box .flex-head {
  display: flex;
  align-items: center;
  gap: 13px;
}

.job-box .flex-head img {
  width: 39.17px;
  height: 39.17px;
  border: 20px;
}

.job-box .flex-head h3 {
  font-size: 11.425px;
  font-weight: 500;
  line-height: 24.481px;
  color: #3a649e;
}

.job-box .flex-head .div-locate {
  display: flex;
  align-items: center;
  font-size: 11.425px;
  line-height: 19.585px;
  color: #7e9ac0;
}

.job-box .second-section {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.job-box .second-section .flex-second {
  display: flex;
  justify-content: space-between;
}

.job-box .second-section .flex-second h3 {
  color: #3a649e;
  font-size: 16.321px;
  font-weight: 700;
  line-height: 24.481px;
}

.job-box .second-section .flex-second span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53.042px;
  height: 22.033px;
  border-radius: 6.528px;
  background: #36a695;
  font-size: 9.792px;
  font-weight: 600;
  line-height: 11.425px;
  color: #fff;
}

.job-box .second-section p {
  color: #808080;
  font-size: 11.425px;
  font-weight: 400;
  line-height: 19.585px;
}

.job-box .third-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 1.1rem;
}

.job-box .third-section .btn-one {
  padding: 8px auto;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  font-size: 13.057px;
  font-weight: 600;
  line-height: 24.481px;
  background-color: #3a649e;
  border-radius: 20px;
  border: 1px solid #3a649e;
  cursor: pointer;
}

.job-box .third-section .btn-two {
  padding: 8px auto;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #3a649e;
  font-size: 13.057px;
  font-weight: 600;
  line-height: 24.481px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #3a649e;
  cursor: pointer;
}

.job-box .status {
  position: absolute;
  background: url(../../assets/statusbg.svg);
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 60.806px;
  height: 170.508px;
}

.job-box .status div {
  color: #fff;
  margin-top: 28px;
  margin-right: 22px;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  transform: rotate(-52.72deg);
}
