.client-slider-container {
    width: 85%;
    margin: 0 auto;
    background: url(../../assets/bg-client.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 3rem;
    border-radius: 15px;
    margin-bottom: 35px;
}

.client-slider-container .client-speak {
    font-size: 42px;
    color: #fff;
    font-weight: 750;
    margin-bottom: 0.8rem;
}

.client-slider-container .customer-box-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.client-slider-container .customer-item{
    /* border: 1px solid red; */
    width: 550px;
    padding: 0.8rem 0.2rem;
}

.client-slider-container .customer-item .customer-content-top {
    background-color: #fff;
    padding: 20px 25px;
    text-align: center;
    margin-bottom: 1rem;
    border-radius: 10px;
}

.client-slider-container .customer-item .customer-content-top h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}

.client-slider-container .customer-item .customer-content-top p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #18191F
}

.client-slider-container .customer-item .customer-content-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    /* border: 1px solid red; */
}

.client-slider-container .customer-item .customer-content-bottom .customer-extra .customer-name{
  font-size: 19px;
  font-weight: 600;
  color: #fff;
}

.client-slider-container .customer-item .customer-content-bottom .customer-extra .customer-title{
    font-size: 16px;
    font-weight: 200;
    color: #fff;
  
}

@media only screen and (max-width: 1250px) {
    .client-slider-container {
        width: 90%;
    }
}


@media only screen and (max-width: 900px) {
    .client-slider-container {
        width: 95%;
        background-repeat: repeat;
        padding: 2rem 1rem;
    }

    .client-slider-container .client-speak {
        font-size: 30px;
    }

    .client-slider-container .customer-box-container {
        flex-wrap: wrap;
    }

    .client-slider-container .customer-item{
        /* border: 1px solid red; */
        width: 100%;
        padding: 0.8rem 0.2rem;
    }
    
}