.date-picker {
  border-radius: 100px;
  border: 1px solid #efefef;
  background: #fff;
  color: #021424;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  padding: 12px 16px;
  outline: none;
}

.date-picker[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../assets/calendar.png");
  background-repeat: no-repeat;
}

.date-picker[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../assets/clock.png");
  background-repeat: no-repeat;
}
