.postjobBtn {
  border-radius: 100px;
  padding: 14px 32px;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  border: none;
  outline: none;
}
