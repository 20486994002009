.filter-box-container .filters-box-wrapper {
  width: auto;
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 8px;
  height: 120vh;
}

.filter-box-container .filters-box-wrapper .filter-header {
  margin: 1.4rem 0;
}

.filter-box-container .filters-box-wrapper .jobListings-divisions {
  margin-top: 0.9rem;
  margin-bottom: 1.8rem;
}

.filter-box-container .filters-box-wrapper .jobListings-divisions h4 {
  color: #245293;
}

.filter-box-container .filters-box-wrapper select {
  padding: 0.4rem 0.5rem;
  width: 100%;
  border: 1px solid #c5d4e7;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}

.filter-box-container .filters-box-wrapper .jobListings-location {
  margin-top: 0.9rem;
  margin-bottom: 1.8rem;
}

.filter-box-container .filters-box-wrapper .jobListings-location h4 {
  color: #245293;
}

.filter-box-container .filters-box-wrapper .jobListings-salary {
  margin-top: 0.9rem;
  margin-bottom: 1.8rem;
}

.filter-box-container .filters-box-wrapper .column {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.filter-box-container .filters-box-wrapper .column .top {
  margin: 0.3rem 0;
}

.filter-box-container .filters-box-wrapper .column h4 {
  color: #245293;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
}

.filter-box-container .filters-box-wrapper .jobListings-topSalary {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0.5rem 0;
}

.filter-box-container .filters-box-wrapper .jobListings-topSalary .flex-input {
  display: flex;
  align-items: center;
  gap: 15px;
}

.filter-box-container
  .filters-box-wrapper
  .jobListings-topSalary
  .flex-input
  .full-auto-complete {
  width: 100%;
}

.filter-box-container
  .filters-box-wrapper
  .jobListings-topSalary
  .flex-input
  .icon {
  color: #c5d4e7;
}

.filter-box-container .filters-box-wrapper .reset-filters {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.filter-box-container .filters-box-wrapper .reset-filters button {
  width: 100%;
  padding: 0.5rem 0.3rem;
  background: transparent;
  border: 1px solid red;
  color: red;
  cursor: pointer;
  border-radius: 4px;
}

@media only screen and (max-width: 1000px) {
  .filter-box-container .filters-box-wrapper {
    padding: 1rem 1.2rem;
    background-color: #fff;
  }
}

@media only screen and (max-width: 500px) {
  .filter-small-screen .filter-box-container .filters-box-wrapper {
    width: 370px;
    padding: 1rem 1rem;
    background-color: #fff;
    border-radius: 8px;
  }
}
