.shortlist-container .shortlist-right {
  width: 100%;
  padding: 0rem 1rem;
  margin-top: 1rem;
}

.shortlist-container .shortlist-right .shortlist-right-bottom h2 {
  font-size: 24px;
  color: #465174;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.2rem;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-header
  .shortlist-header-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-right-content {
  background-color: #fff;
  border-radius: 7px;
  padding: 2rem 0;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-right-content
  .job-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-right-content
  .job-list-container
  .shortlist-jobItem {
  width: 30%;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-right-content
    .job-list-container
    .shortlist-jobItem {
    width: 45%;
  }
}

@media only screen and (max-width: 1000px) {
  .shortlist-container .shortlist-right {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 750px) {
  .shortlist-container .shortlist-left {
    display: none;
  }

  .shortlist-container .shortlist-right-top {
    display: none;
  }

  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-right-content
    .job-list-container
    .shortlist-jobItem {
    width: 95%;
    cursor: pointer;
  }

  .shortlist-container .shortlist-right {
    padding: 2rem 1rem;
  }

  .shortlist-right .shortlist-right-bottom .shortlist-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
    margin: 0 0 1.2rem;
  }

  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-header
    .shortlist-header-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }
}
