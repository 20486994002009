.modalLaterBtn {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #245293;
  color: #245293;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
