.findJobs-container {
    width: 85%;
    margin: 0 auto;
    padding: 2rem 0.5rem;
    background-color: #CFF2FF;
    border-radius: 10px;
}

.findJobs-container h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 19px;
    color: #245293;
    width: 50%;
    margin: 1rem auto;
}


/* jobs tabs*/
/* jobs tabs*/
.findJobs-container .findJobs-tabs {
    display: flex;
    justify-content: center;
    gap: 0.6rem;
    margin-bottom: 30px;
    flex-wrap: wrap;
    
}

.findJobs-container .findJobs-tabs .jobs-tabs-1 {
    border: 1px solid #245293;
    padding: 0.2rem 0.5rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #245293;
    background-color: #fff;
}

.findJobs-container .findJobs-tabs .jobs-tabs {
    border: 1px solid #A9DBEE;
    padding: 0.2rem 0.5rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #245293;
}
/* jobs tabs*/
/* jobs tabs*/


/* search box */
/* search box */
.findJobs-container .findJobs-search-box {
    border-radius: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0.6rem 0.3rem 0.6rem 0;
    margin-bottom: 25px;
    width: 80%;
    margin: 0 auto;
}

.findJobs-container .findJobs-search-box .input-1 {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 4px 0;
    width: 35%;
    padding-left: 1.5rem;
    border-right: 1.5px solid #A9DBEE;
}

.findJobs-container .findJobs-search-box input {
    padding: 2px 5px;
    border: none;
    outline: none;
    width: 260px;
}

.findJobs-container .findJobs-search-box .findJobs-button {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 30%;
    margin-right: 10px;
}

.findJobs-container .findJobs-search-box .findJobs-button button .arrow-right {
    font-size: 15px;
    font-weight: 400;
}

.findJobs-container .findJobs-search-box .findJobs-button button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    background-color: #245293;
    color: #fff;
    font-size: 16px;
    padding: 8px 25px;
    cursor: pointer;
    border-radius: 20px;
    outline: none;
    border: none;
    width: 70%;
}
/* search box */
/* search box */



/* job result container */
/* job result container */
.findJobs-container .job-result-container {
    /* border: 1px solid red; */
    margin-top: 0.5rem;
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}

.findJobs-container .proxy-job-container .no-jobs-found{
    text-align: center;
    margin: 2rem 0;
    font-size: 1.7rem;
    font-weight: 800;
}


.findJobs-container .findJobs-jobitem {
    width: 365px;
}
/* job result container */
/* job result container */


/* view more button */
/* view more button */
.findJobs-container .button-full {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.findJobs-container .button-full button {
    color: #245293;
    border: 1px solid #245293;
    background-color: #fff;
    outline: none;
    padding: 0.3rem 0.9rem;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
    font-weight: 700;
}
/* view more button */
/* view more button */



/* media queries, obviously */
/* media queries, obviously */

@media only screen and (max-width: 1440px) {

    .findJobs-container .findJobs-search-box .findJobs-button button {
        
        width: 95%;
    }

}

@media only screen and (max-width: 1250px) {
    .findJobs-container {
        width: 90%;
    }

    .findJobs-container h3 {
        width: 50%;
    }

    .findJobs-container .findJobs-search-box input {
        width: 180px;
    }

    .findJobs-container .findJobs-search-box .findJobs-button {
        width: 65%;
    }
}

/* @media only screen and (max-width: 850px) {
    .findJobs-container .findJobs-search-box {
        width: 95%;
        margin: 0 auto;
    }

    .findJobs-container .findJobs-search-box .input-1 {
        padding: 8px 9px;
    }

    .findJobs-container .findJobs-search-box input {
        width: 100px;
    }
} */


@media only screen and (max-width: 900px) {
    .findJobs-container {
        width: 95%;
        padding: 2rem 0.5rem;
    }

    .findJobs-container h3 {
        width: 75%;
    }

    .findJobs-container .findJobs-search-box {
        padding: 1rem 1rem;
        width: 100%;
        flex-direction: column;
        border-radius: 10px;
    }
    
    .findJobs-container .findJobs-search-box .input-1 {
        gap: 5px;
        border-bottom: 1px solid #c9c8c8;
        width: 100%;
        padding: 0.5rem 0;
        display: flex;
        justify-content: flex-start;
        border-right: none;
    }

    .findJobs-container .findJobs-search-box .input-1 input {
        border-right:none;
        padding: 10px 5px;
    }

    .findJobs-container .findJobs-search-box .findJobs-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
    }

    .findJobs-container .findJobs-search-box .findJobs-button button {
        width: 100%;
        justify-content: center;
        padding: 0.4rem 0;
        font-size: 20px;
    }

    .findJobs-container .button-full button {
        padding: 0.3rem 0.5rem;
        font-size: 12px;
    }
}

@media only screen and (max-width: 650px) {
    .findJobs-container h3 {
        width: 95%;
        font-size: 28px;
    }
}