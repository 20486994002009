@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

.nav-container {
  width: 100%;
  position: fixed;
  top: 0;
  /* background-color: #A9DBEE; */
  margin-bottom: 20px;
  z-index: 50;
}

header .mobile-nav {
  display: none;
}

.nav-container .nav_title {
  width: 45px;
  height: 45px;
}

.nav-container .nav_title img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

header {
  width: 85%;
  margin: 20px auto 20px;
  border: 1px solid #d3c5c5;
  z-index: 100;
  background-color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  /* border: 1px solid red; */
}

nav a {
  margin: 0 1rem;
  color: #245293;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}

nav a:hover {
  color: grey;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  display: none;
  opacity: 0;
  font-size: 1.3rem;
}

header .nav-search-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  display: none;
  opacity: 0;
  font-size: 1.3rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

.nav-container .login-button {
  font-size: 14px;
  padding: 0.4rem 1.6rem;
  border-radius: 20px;
  outline: none;
  border: none;
  background-color: #245293;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  nav a {
    font-weight: 600;
    font-size: 11px;
    margin: 0 0.5rem;
  }

  .nav-container .login-button {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1250px) {
  header {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  header .nav-btn {
    display: block;
    font-size: 24px;
    opacity: 1;
    color: #212121;
  }

  header .nav-search-btn {
    display: block;
    font-size: 25px;
    opacity: 1;
    color: #212121;
    z-index: 50;
  }

  header .mobile-nav {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  header {
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    width: 95%;
  }

  header .nav-close-btn {
    margin-top: 7px;
    font-size: 24px;
    color: black;
  }

  .nav-container .nav_title {
    width: 60px;
    height: 55px;
    object-fit: cover;
    z-index: 9999;
  }

  header nav {
    position: fixed;
    top: 0;
    padding: 7rem 1rem 1rem 1rem;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    background-color: white;
    color: black;
    transition: 1s;
    transform: translateY(-150vh);
  }

  .homee {
    display: block;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    font-size: 1.8rem;
    font-weight: 100;
  }

  nav a {
    font-size: 1.1rem;
    color: #245293;
    border-bottom: 1px solid #c4e4f5;
    padding: 0.7rem 0;
    width: 100%;
    margin: 0 0;
  }

  .nav-container .login-button {
    font-size: 17px;
    padding: 0.4rem 1.6rem;
    width: 80%;
    margin: 0 10%;
    padding: 0.7rem 0;
  }

  nav .login-btn-link {
    border-bottom: none;
    margin-top: 0.5rem;
  }
}
