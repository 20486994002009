.profile-page-container {
  /* height: 100%; */
  width: 100%;
}

.profile-page-container h6 {
  font-size: 24px;
  font-weight: 500;
  color: #245293;
  line-height: 29px;
}

/* profile box 1 */
.profile-page-container .profile-page-details .profile-box-1 {
  border: 1px solid #dadada;
  margin: 1rem 0;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.profile-page-container .profile-page-details .profile-box-1 .profile-nameBox {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile-page-container
  .profile-page-details
  .profile-box-1
  .profile-nameBox
  .nameBox-image
  img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #c7c7c7;
}

.profile-page-container
  .profile-page-details
  .profile-box-1
  .profile-nameBox
  .nameBox-fullname {
  color: #202020;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.2px;
}

.profile-page-container
  .profile-page-details
  .profile-box-1
  .profile-nameBox
  .nameBox-job {
  color: #818181;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.18px;
}

.profile-page-container
  .profile-page-details
  .profile-box-1
  .profile-nameBox
  .nameBox-location {
  color: #818181;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
}

.profile-page-container .profile-page-details button {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #dadada;
  background-color: #fff;
  border-radius: 2px;
  cursor: pointer;
  padding: 9px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
/* profile-box-1 */

/* profile box 2*/
.profile-page-container .profile-page-details .profile-box-2 {
  border: 1px solid #dadada;
  margin: 1rem 0;
  border-radius: 6px;
  padding: 1rem;
}

.profile-page-container .profile-page-details .profile-box-2 .profile-box2-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.profile-page-container
  .profile-page-details
  .profile-box-2
  .profile-box2-top
  .heading {
  color: #000;
  font-size: 22px;
  font-weight: 500;
}

.profile-page-container
  .profile-page-details
  .profile-box-2
  .profile-box2-bottom {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  column-gap: 80px;
}

.profile-page-container
  .profile-page-details
  .profile-box-2
  .profile-box2-bottom
  .leftbox {
  width: 350px;
}

.profile-page-container
  .profile-page-details
  .profile-box-2
  .profile-box2-bottom
  h5 {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 500;
}

.profile-page-container
  .profile-page-details
  .profile-box-2
  .profile-box2-bottom
  p {
  color: #202020;
  font-size: 18px;
  font-weight: 500;
}

.profile-page-container
  .profile-page-details
  .profile-box-2
  .profile-box2-bottom
  .entry-1 {
  margin-bottom: 0.6rem;
}
/* profile box 2*/

/* profile box 3 */
.profile-page-container .profile-page-details .profile-box-3 {
  border: 1px solid #dadada;
  margin: 1rem 0;
  border-radius: 6px;
  padding: 1rem;
}

.profile-page-container .profile-page-details .profile-box-3 .profile-box3-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.profile-page-container
  .profile-page-details
  .profile-box-3
  .profile-box3-top
  .heading {
  color: #000;
  font-size: 22px;
  font-weight: 500;
}

.profile-page-container
  .profile-page-details
  .profile-box-3
  .profile-box3-bottom {
  display: flex;
}

.profile-page-container
  .profile-page-details
  .profile-box-3
  .profile-box3-bottom
  .leftbox {
  width: 350px;
}

.profile-page-container
  .profile-page-details
  .profile-box-3
  .profile-box3-bottom
  h5 {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 500;
}

.profile-page-container
  .profile-page-details
  .profile-box-3
  .profile-box3-bottom
  p {
  color: #202020;
  font-size: 18px;
  font-weight: 500;
}

.profile-page-container
  .profile-page-details
  .profile-box-3
  .profile-box3-bottom
  .entry-1 {
  margin-bottom: 0.6rem;
}

/* profile box 3 */

/* profile box 4 */
.profile-page-container .profile-page-details .profile-box-4 {
  border: 1px solid #dadada;
  margin: 1rem 0;
  border-radius: 6px;
  padding: 1rem;
}

.profile-page-container .profile-page-details .profile-box-4 .profile-box4-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-top
  .heading {
  color: #000;
  font-size: 22px;
  font-weight: 500;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom {
  display: flex;
  align-items: center;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom
  .leftbox {
  width: 350px;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom
  .leftbox
  .entry-2 {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 0.8rem;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom
  .leftbox
  .icon-box {
  border: 1px solid #dadada;
  padding: 8px;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom
  .leftbox
  .icon-box
  .iconn {
  font-size: 1.2rem;
  color: #555555;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom
  p {
  color: #202020;
  font-size: 21px;
  font-weight: 500;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom
  h5 {
  color: #8c8c8c;
  font-size: 18px;
  font-weight: 400;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom
  .rightbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.profile-page-container
  .profile-page-details
  .profile-box-4
  .profile-box4-bottom
  .rightbox
  h4 {
  color: #8c8c8c;
  font-size: 18px;
  font-weight: 500;
}
/* profile box 4 */

/* profile box 5 */
.profile-page-container .profile-page-details .profile-box-5 {
  border: 1px solid #dadada;
  margin: 1rem 0;
  border-radius: 6px;
  padding: 1rem;
}

.profile-page-container .profile-page-details .profile-box-5 .profile-box5-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.profile-page-container
  .profile-page-details
  .profile-box-5
  .profile-box5-top
  .heading {
  font-size: 20px;
  font-weight: 600;
}

.profile-page-container
  .profile-page-details
  .profile-box-5
  .profile-box5-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* profile box 5 */

/* profile box 6 */
.profile-page-container .profile-page-details .profile-box-6 {
  border: 1px solid #dadada;
  margin: 1rem 0;
  border-radius: 6px;
  padding: 1rem;
}

.profile-page-container .profile-page-details .profile-box-6 .profile-box6-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.profile-page-container
  .profile-page-details
  .profile-box-6
  .profile-box6-top
  .heading {
  font-size: 20px;
  font-weight: 600;
}

.profile-page-container
  .profile-page-details
  .profile-box-6
  .profile-box6-bottom {
  width: 450px;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.profile-page-container
  .profile-page-details
  .profile-box-6
  .profile-box6-bottom
  span {
  border-radius: 10px;
  background-color: #efefef;
  padding: 5px 10px;
  color: #606060;
  font-size: 18px;
  font-weight: 500;
}
/* profile box 6 */

@media only screen and (max-width: 650px) {
  .profile-page-container .profile-page-details .profile-box-1 {
    align-items: flex-end;
  }

  .profile-page-container
    .profile-page-details
    .profile-box-2
    .profile-box2-bottom {
    display: flex;
    gap: 25px;
  }

  .profile-page-container
    .profile-page-details
    .profile-box-6
    .profile-box6-bottom {
    width: 400px;
    gap: 15px;
  }

  .profile-page-container .profile-page-details .profile-box-1 {
    padding: 0.5rem 0.3rem;
  }

  .profile-page-container .profile-page-details .profile-box-3 {
    padding: 0.5rem 0.3rem;
  }

  .profile-page-container .profile-page-details .profile-box-4 {
    padding: 0.5rem 0.3rem;
  }

  .profile-page-container .profile-page-details .profile-box-5 {
    padding: 0.5rem 0.3rem;
  }

  .profile-page-container .profile-page-details .profile-box-6 {
    padding: 0.5rem 0.3rem;
  }

  .profile-page-container
    .profile-page-details
    .profile-box-1
    .profile-nameBox {
    gap: 10px;
  }

  .profile-page-container
    .profile-page-details
    .profile-box-1
    .profile-nameBox
    .nameBox-image
    img {
    width: 80px;
    height: 80px;
  }

  .profile-page-container .profile-page-details .profile-box-2 {
    border: 1px solid #dadada;
    margin: 1rem 0;
    border-radius: 6px;
    padding: 0.5rem 0.3rem;
  }

  .profile-page-container
    .profile-page-details
    .profile-box-2
    .profile-box2-bottom {
    width: 70%;
    column-gap: 80px;
  }

  .profile-page-container
    .profile-page-details
    .profile-box-6
    .profile-box6-bottom {
    padding: 0.5rem 0;
    display: flex;
    gap: 15px;
    width: auto;
  }

  .profile-page-container
    .profile-page-details
    .profile-box-6
    .profile-box6-bottom
    span {
    border-radius: 10px;
    background-color: #efefef;
    padding: 5px 10px;
    color: #606060;
    font-size: 18px;
    font-weight: 500;
    width: fit-content;
  }
}
