.conversation-broad {
  padding: 1.5rem 1rem;
}

.conversation-broad h6 {
  color: #7b839c;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 1.5rem;
}

.conversation-broad .chats-list {
  margin-top: 0.7rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.conversation-broad .chats-list .single-chat-contact {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding-left: 1.5rem;
  border-radius: 11px;
  cursor: pointer;
}

.background-apply {
  background: linear-gradient(
    90deg,
    rgba(240, 243, 255, 0.74) 0%,
    rgba(240, 243, 255, 0) 50.52%
  );
  padding: 1.3rem 0 1.3rem 0;
}

.conversation-broad .chats-list .single-chat-contact .text-content h5 {
  color: #465174;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
}

.conversation-broad .chats-list .single-chat-contact .text-content p {
  color: #626b89;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.conversation-broad
  .chats-list
  .single-chat-contact
  .text-content
  .date-content {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: #7b839c;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 650px){
  .conversation-broad {
    padding: 1rem 0.5rem;
  }

  .conversation-broad h6 {
    padding-left: 0;
  }

  .conversation-broad .chats-list .single-chat-contact {
    padding-left: 1rem;
  }
}
